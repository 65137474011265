<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="$t('SelectCategories')"
    v-on:submit="save"
    content-class="pl-0 pr-0 pb-0 mb-2 max-400"
    :hide-submit="error || !categories || categories.length == 0"
  >
    <template v-slot:content>
      <v-list v-if="categories && categories.length > 0" class="pt-0 pb-0">
        <v-list-item-group
          v-model="selected"
          active-class="global-list-item-active"
          multiple
        >
          <template v-for="(cat, index) in categories">
            <v-list-item :key="cat.id">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title class="global-list-header mb-0">{{
                    cat.name | capitalize
                  }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon v-if="!active" color="grey lighten-1">
                    $vuetify.icons.starOutline
                  </v-icon>

                  <v-icon v-else color="yellow darken-3">
                    $vuetify.icons.star
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider
              v-if="index < categories.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
      <div v-else class="text-center pb-5">{{ $t("NoCategories") }}</div>

      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
      "SelectCategories": "Select categories",
      "AlbumUpdated": "Album updated!",
      "MethodNotFound": "Method not found! Refresh the page and try again.",
      "PostUpdated": "Post updated!",
      "NoCategories": "There is no categories to select!"
    },
    "sv": {
      "SelectCategories": "Välj kategorier",
      "AlbumUpdated": "Albumet uppdaterades!",
      "MethodNotFound": "Anropet kunde inte genomföras. Vänligen uppdatera sidan och försök igen.",
      "PostUpdated": "Inlägget uppdaterades!",
      "NoCategories": "Det finns inga kategorier att välja!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "diary_select_categories_dialog",
  computed: {
    ...mapState({
      global_categories: (state) => state.diary.categories.list,
    }),
  },
  components: {},
  data: () => ({
    is_open: false,
    error: null,
    is_saving: false,

    post: null,

    categories: [],
    selected: [],
  }),
  created: function () {},
  methods: {
    ...mapActions({
      updateCategories: "diary/news/updateCategories",
    }),
    rowClick(child) {
      child.is_selected = !child.is_selected;
    },
    start() {
      var self = this;
      self.selected = [];
      self.categories = [...self.global_categories];
      self.is_saving = false;
      self.error = null;
      self.is_open = true;
    },
    setMarked(selectedCategories) {
      var self = this;
      self.categories.forEach((child, i) => {
        if (selectedCategories) {
          var ch = selectedCategories.find((p) => p.id == child.id);

          if (ch) {
            self.selected.push(i);
          }
        }
      });
    },
    getMarked() {
      var data = [];
      var self = this;

      self.selected.forEach((val) => {
        data.push(self.categories[val]);
      });

      return data;
    },

    openBlogPost(post) {
      var self = this;
      self.start();
      self.post = { ...post };

      self.setMarked(self.post.categories);
    },

    save() {
      var self = this;
      self.error = null;
      self.is_saving = true;

      if (self.post) {
        self.updateBlogPostCategories();
      } else {
        self.$errorNort(self.$t("MethodNotFound"));
      }
    },

    updateBlogPostCategories() {
      var self = this;
      var data = self.getMarked();

      self
        .updateCategories({
          id: self.post.id,
          categories: data,
        })
        .then(function () {
          self.is_saving = false;
          self.is_open = false;
          self.post.categories = data;
          self.$emit("updated", self.post);
          self.$successNoty(self.$t("PostUpdated"));
        })
        .catch(function (error) {
          self.error = error;
          self.is_saving = false;
        });
    },
  },
};
</script>